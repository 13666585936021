.App {
  font-family: sans-serif;
  text-align: left;
}

.my-editor {
  width: 100%;
  height: 100%;
  display: flex;
}
